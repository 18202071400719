/// <reference lib="dom" />
import * as Sentry from "@sentry/browser";
import { dom } from "./baseDom.js";
import "./footer.js";
import { notNullOrDie } from "../shared/helpers.js";

history.scrollRestoration = "manual";

Sentry.init({
    dsn: "https://c67967066d1f4d4fb897d36759088dc9@o220753.ingest.sentry.io/4504369645813760",
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
});

fetch("/locale").then(async (response) => {
    const locale: Record<
        string,
        Record<string, string>
    > = await response.json();
    dom.languageSelect.removeAttribute("disabled");
    dom.languageSelect.value = dom.html.lang;
    dom.languageSelect.addEventListener("change", (ev) => {
        const language = (ev.target as HTMLSelectElement).value;
        dom.html.setAttribute("lang", language);
        setCookie("lang", language, 1000);
        document.querySelectorAll("[data-t]").forEach((x) => {
            const key = notNullOrDie(x.getAttribute("data-t"));
            x.innerHTML = locale[key][language];
        });
    });
});

function setCookie(cname: string, cvalue: string, exdays: number) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
