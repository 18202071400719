import { query } from "./dom.js";

const dom = {
    contactsLink: query<"span">(".footer-contacts-link"),
    contacts: query<"span">(".footer-contacts"),
};

dom.contactsLink.addEventListener("click", () => {
    dom.contacts.style.display = "block";
    dom.contacts.scrollIntoView();
});
